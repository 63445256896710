.App {
  text-align: center;
}
.card {
  --clay-background: #f76d6d;
  --clay-border-radius: 48px;
  color: #f1f1f1;
  padding: 48px;
  font-family: "Fredoka One", cursive;
}
.grid {
  /* width: 100%;
  height: 100%; */
  display: grid;
  grid-template-columns: auto repeat(5, 1fr);
  grid-template-rows: auto repeat(23, 1fr);
  column-gap: 10px;
  row-gap: 15px;
  justify-items: stretch;
  align-items: stretch;
  justify-content: center;
  align-content: stretch;
  padding: 20px;
}
.weather {
  --clay-background: #ff97e0;
  color: #f1f1f1;
  font-family: "Fredoka One", cursive;
  padding: 12px;
  width: 50%;
  margin: auto;
}

.partielBox {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.exam {
  padding: 15px;
}

.day {
  --clay-background: #f76d6d;
  color: #f1f1f1;
  padding: 24px;
  font-family: "Fredoka One", cursive;
  grid-row: 1/2;
}
.hour {
  font-family: "Fredoka One", cursive;
  grid-column: 1/2;
}
.course {
  padding: 15px;
}
.Théoriename {
  font-family: "Fredoka One", cursive;
  color: #f1f1f1;
}
.pratiquesname {
  font-family: "Fredoka One", cursive;
  color: #202020;
}
.Théorie {
  --clay-background: #f4976c;
}
.pratiques {
  --clay-background: #cbff97;
}

.lundi {
  grid-column: 2/3;
}
.mardi {
  grid-column: 3/4;
}
.mercredi {
  grid-column: 4/5;
}
.jeudi {
  grid-column: 5/6;
}
.vendredi {
  grid-column: 6/7;
}
.h8h00 {
  grid-row: 3/5;
}
.h10h00 {
  grid-row: 7/9;
}
.h12h00 {
  grid-row: 11/13;
}
.h14h00 {
  grid-row: 15/17;
}
.h16h00 {
  grid-row: 19/21;
}
.h18h00 {
  grid-row: 23/25;
}
.h1012 {
  grid-row: 7/12;
}
.h1418 {
  grid-row: 15/24;
}
.h0810 {
  grid-row: 3/7;
}
.h1416 {
  grid-row: 15/20;
}
.h10301230 {
  grid-row: 8/13;
}
.h13301530 {
  grid-row: 14/19;
}
.h1618 {
  grid-row: 19/24;
}
.info {
  position: absolute;
  margin: auto;
  padding: 15px;
  font-size: smaller;
}

@media only screen and (max-width: 760px) {
  .App {
    font-size: 15px;
    margin-left: 50px;
  }
  .day {
    padding: 8px;
  }
  .course {
    padding: 8px;
  }
  .grid {
    /* overflow-x: scroll; */
    min-width: 500px;
  }
}
